import React from 'react'
import HeaderPaginas from '../../../components/headerPaginas'
import Layout from '../../../components/layout'
import Seo from '../../../components/SEO/SEO'
import "../../../styles/servicios.scss"
import { GrDatabase, GrShield, GrStatusWarning, GrUserAdmin, GrStorage, GrCloudUpload, GrMoney, GrAscend } from "react-icons/gr"
import { IconContext } from 'react-icons'
import { Link } from 'gatsby'
import CtaServicios from '../../../components/ctaServicios'

export default function Vps(props) {

    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Servidor Virtual Privado" imagen="/img/cabeceras/Cabecera-VPS.jpg" />
                <div className="intro-servicio">
                    <p>Saltar a la nube con la seguridad que te damos en nuestras VPS y con nuestro Cloud Robusto: con nuestra capa de ciberseguridad y en un data center local: sin sorpresas.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="limitador bloque-grid">
                    <div className="titulo destacado">
                        <h2>¿Qué es?</h2>
                    </div>
                    <div className="texto">
                        <p>Un servidor virtual privado (VPS, del inglés virtual private server) es el resultado de particionar un servidor físico en varios servidores que funcionan como si se ejecutaran en su propia máquina.</p>
                        <p>Los VPS son la solución perfecta para disfrutar de las ventajas de una máquina dedicada a un precio más asequible que otras soluciones. Sin que compres infraestructura cara, sin que tengas que mantenerla. Encendemos y apagamos en función de tus procesos y necesidades. Además, ofrece más independencia y recursos que un hosting compartido. Cada VPS funciona bajo su propio sistema operativo y puede ser reiniciado de forma independiente.</p>
                    </div>
                </section>
                <section className=" limitador bloque-grid">
                    <div className="titulo destacado">
                        <h2>¿Para quién es?</h2>
                    </div>
                    <div className="texto">
                        <p>Para profesionales o empresas que necesitan una solución rápida, ágil y sin complicaciones para gestionar sus aplicaciones y contenidos.</p>
                    </div>
                </section>
                <section className=" limitador bloque-grid">
                    <div className="titulo destacado">
                        <h2>¿Dónde está?</h2>
                    </div>
                    <div className="texto">
                        <p>En nuestro <Link to="/cloud-robusto/">cloud robusto</Link>. Todos nuestros VPS se alojan en nuestro data center local alojado en España.</p>
                    </div>
                </section>
                <section className="">
                    <div className="bloque-tips limitador">
                        <h2>Características destacadas</h2>
                        <IconContext.Provider value={{ fill: "#1f1f1f", stroke: "#1f1f1f", color: "#1f1f1f", size: "40%", className: "serv-icono-svg" }}>
                            <ul>
                                <li>
                                    <div className="tip-icono"><GrDatabase /></div>
                                    <p>
                                        Data center securizado propio
                                    </p>
                                </li>
                                <li>
                                    <div className="tip-icono"><GrShield /></div>
                                    <p>
                                        Seguridad certificada
                                    </p>
                                </li>
                                <li>
                                    <div className="tip-icono"><GrStatusWarning /></div>
                                    <p>
                                        Exclusiones de contenido peligroso
                                    </p>
                                </li>
                                <li>
                                    <div className="tip-icono"><GrUserAdmin /></div>
                                    <p>
                                        Administrado por nuestros técnicos
                                    </p>
                                </li>
                                <li>
                                    <div className="tip-icono"><GrAscend /></div>
                                    <p>
                                        Escalable sin dramas
                                    </p>
                                </li>
                                <li>
                                    <div className="tip-icono"><GrStorage /></div>
                                    <p>
                                        Discos SSD de alta calidad
                                    </p>
                                </li>
                                <li>
                                    <div className="tip-icono"><GrCloudUpload /></div>
                                    <p>
                                        Cloud: olvida el hardware
                                    </p>
                                </li>
                                <li>
                                    <div className="tip-icono"><GrMoney /></div>
                                    <p>
                                        Presupuesto competitivo
                                    </p>
                                </li>
                            </ul>
                        </IconContext.Provider>
                    </div>
                </section>
            </div>
            {/* CTA para contacto */}
            <CtaServicios />
        </Layout>
    )
}
